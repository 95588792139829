//* CSS
import './styles/foundations.css';

//* JS
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'cookieconsent';
import SvgConversion from './scripts/utils/SvgConversion';
import SetFixedHeader from './scripts/utils/SetFixedHeader';
import Accordion from './scripts/components/Accordion';
import Metric from './scripts/components/Metric';
import DetectMobileDevice from './scripts/utils/DetectMobileDevice';
import ConfigureSelectChoices from './scripts/utils/ConfigureSelectChoices';
import ConfigureSmoothScroll from './scripts/utils/ConfigureSmoothScroll';
import FetchCollectionEndpoint from './scripts/utils/FetchEntryEndpoint';
import Carousel from './scripts/components/Carousel';

document.addEventListener('DOMContentLoaded', function () {
  const html = document.querySelector('html');
  const body = document.querySelector('body');

  //* Enable fixed header on scroll
  //* Check if the fixed header logic has been enabled
  //* if so, get the site header and add the fixed class to it depending on its top offset
  const fixedHeaderElement = document.querySelector('.fixed-header-enabled');
  const headerWrapper = document.querySelector('.header-wrapper');
  const siteHeader = document.querySelector('.site-header');

  if (fixedHeaderElement) {
    //* Set body's top padding equal to header height
    //* Prevents content from jumping when header becomes fixed
    let siteHeaderInfo = siteHeader.getBoundingClientRect();
    let headerHeight = siteHeaderInfo.height;
    headerWrapper.style.height = `${headerHeight}px`;

    window.addEventListener('resize', () => {
      siteHeaderInfo = siteHeader.getBoundingClientRect();
      headerHeight = siteHeaderInfo.height;
      headerWrapper.style.height = `${headerHeight}px`;
    });

    const siteHeaderOffset = siteHeader.offsetTop;

    SetFixedHeader(siteHeader, siteHeaderOffset);
  }

  //* Smooth scroll on anchor link click
  ConfigureSmoothScroll(siteHeader);

  //* Mobile menu toggling
  const mobileMenuBtns = [
    ...document.querySelectorAll('.mobile-menu-toggle-button'),
  ];
  const mobileMenu = document.querySelector('.mobile-menu');
  const mobileMenuLinks = [
    ...document.querySelectorAll('.mobile-nav-menu .nav__link'),
  ];

  const hamburgerNavMenu = document.querySelector('.hamburger-nav-menu');
  const hamburgerLinks = [
    ...document.querySelectorAll('.hamburger-nav-menu .nav__link'),
  ];

  if (mobileMenuBtns) {
    mobileMenuBtns.forEach(mobileMenuBtn => {
      mobileMenuBtn.addEventListener('click', e => {
        e.preventDefault();

        //* Toggle class on mobile menu btn
        html.classList.toggle('menu-open');
        body.classList.toggle('menu-open');
        mobileMenuBtn.classList.toggle('active-btn');
        if (hamburgerNavMenu) {
          hamburgerNavMenu.classList.toggle('open');
        }
        if (mobileMenu) {
          mobileMenu.classList.toggle('open');
        }
      });
    });
  }

  if (hamburgerLinks) {
    hamburgerLinks.forEach(hamburgerLink => {
      hamburgerLink.addEventListener('click', () => {
        html.classList.remove('menu-open');
        body.classList.remove('menu-open');
        hamburgerNavMenu.classList.toggle('open');
      });
    });
  }

  if (mobileMenuLinks) {
    mobileMenuLinks.forEach(mobileMenuLink => {
      mobileMenuLink.addEventListener('click', () => {
        html.classList.toggle('menu-open');
        body.classList.toggle('menu-open');
        // mobileMenuBtn.classList.toggle('active-btn');
        mobileMenu.classList.toggle('open');
      });
    });
  }

  //* Read More Button Toggle
  const flexGridContainers = document.querySelectorAll('.flex-grid-container');

  if (flexGridContainers) {
    flexGridContainers.forEach(container => {
      const numItemsPerRow = container.dataset.items;
      const showMoreBtn = container.querySelector('.show-more');
      const showLessBtn = container.querySelector('.show-less');
      const containerItems = [...container.querySelectorAll('.grid-item')];
      const buttons = [...container.querySelectorAll('.btn')];

      containerItems.forEach((item, index) => {
        if (numItemsPerRow === '-1') {
          item.classList.add('active');
        } else if (index < numItemsPerRow) {
          item.classList.add('active');
        }

        if (index + 1 === containerItems.length) {
          item.classList.add('last-item');
        }
      });

      const activeItems = [...container.querySelectorAll('.grid-item.active')];

      if (activeItems.length === parseInt(numItemsPerRow)) {
        showLessBtn.classList.add('hidden');
      } else {
        showLessBtn.classList.add('active');
      }

      let numItemsShownLast = numItemsPerRow;

      if (buttons) {
        buttons.forEach(button => {
          if (
            numItemsPerRow === '-1' ||
            containerItems.length <= parseInt(numItemsPerRow)
          ) {
            button.classList.add('hidden');
          }

          button.addEventListener('click', e => {
            e.preventDefault();

            const hiddenItems = [
              ...container.querySelectorAll('.grid-item:not(.active)'),
            ];

            if (button.classList.contains('show-more')) {
              const nextItemGroup = hiddenItems.slice(0, numItemsPerRow);

              nextItemGroup.forEach(item => item.classList.add('active'));

              numItemsShownLast = nextItemGroup.length;

              showLessBtn.classList.remove('hidden');
              showLessBtn.classList.remove('active');

              if (hiddenItems.length < numItemsPerRow) {
                showMoreBtn.classList.remove('active');
                showMoreBtn.classList.add('hidden');
              } else {
                showMoreBtn.classList.remove('hidden');
                showMoreBtn.classList.add('active');
              }
            }

            const currentActiveItems = [
              ...container.querySelectorAll('.grid-item.active'),
            ];

            if (button.classList.contains('show-less')) {
              const prevItemGroup = currentActiveItems.slice(
                -numItemsShownLast
              );
              prevItemGroup.forEach(item => item.classList.remove('active'));

              showMoreBtn.classList.remove('hidden');
              showMoreBtn.classList.add('active');

              numItemsShownLast = numItemsPerRow;
            }

            const updatedActiveItems = [
              ...container.querySelectorAll('.grid-item.active'),
            ];

            if (updatedActiveItems.length === parseInt(numItemsPerRow)) {
              showLessBtn.classList.remove('active');
              showLessBtn.classList.add('hidden');
            } else {
              showLessBtn.classList.remove('hidden');
              showLessBtn.classList.add('active');
            }
          });
        });
      }

      // if (showMoreBtn) {
      //   showMoreBtn.addEventListener('click', e => {
      //     e.preventDefault();

      //     showLessBtn.style.display = 'block';

      //     if (hiddenItems.length < numItemsPerRow) {
      //       showMoreBtn.style.display = 'none';
      //     } else {
      //       showMoreBtn.style.display = 'block';
      //     }
      //   });
      // }

      // if (showLessBtn) {
      //   showLessBtn.addEventListener('click', e => {
      //     e.preventDefault();

      //     const currentActiveItems = [
      //       ...container.querySelectorAll('.grid-item.active'),
      //     ];

      //     if (currentActiveItems.length === numItemsPerRow) {
      //       showLessBtn.style.display = 'none';
      //     } else {
      //       showLessBtn.style.display = 'block';
      //     }

      //     console.log(numItemsShownLast);
      //   });
      // }
    });
  }

  const externalLinks = [...document.querySelectorAll('.new-tab')];

  externalLinks.forEach((link) => {
    const a = link.firstChild;
    a.target = "_blank";
  })

  //* Accordions functionality
  const accordions = [...document.querySelectorAll('.accordion')];

  if (accordions) {
    accordions.forEach(accordion => Accordion(accordion));
  }

  //* Metrics functionality
  const metrics = [...document.querySelectorAll('.metric')];

  if (metrics) {
    metrics.forEach(metric => Metric(metric));
  }

  //* Carousel
  const carousels = [...document.querySelectorAll('.swiper-container')];

  if (carousels) {
    carousels.forEach(carousel => {
      const numSlides = carousel.dataset.slides;
      Carousel(carousel, numSlides);
    });
  }

  //* Select Dropdowns
  const selects = [...document.querySelectorAll('select')];

  //* Convert selects to unordered lists to enable option styling on non-mobile devices
  if (!DetectMobileDevice()) {
    selects.forEach(select => ConfigureSelectChoices(select));
  }

  // Team member modal
  const bioEndpoints = [...document.querySelectorAll('.bio')];
  const bodyContent = document.querySelector('.body-content');
  const modalContainer = document.querySelector('.team-modal-container');
  const modal = document.querySelector('.modal-div');
  const dataObj = [];
  // const btnClose = document.querySelector(
  //   'a.btn.primary-color-background.modal-btn-close'
  // );

  const closeModal = () => {
    // bodyContent.style.paddingRight = '0';
    modalContainer.classList.remove('modal-open');
    html.classList.remove('modal-open');
    body.classList.remove('modal-open');
  };

  document.addEventListener('keyup', e => {
    if (e.code === 'Escape') {
      closeModal();
    }
  });

  // btnClose.addEventListener('click', () => {
  //   closeModal();
  // });

  // close modal on click outside
  document.addEventListener('click', e => {
    const clickedInside = modal.contains(e.target);
    if (!clickedInside) {
      closeModal();
    }
  });

  bioEndpoints.forEach(endpoint => {
    const entryId = endpoint.dataset.entry;
    const jobTitle = document.querySelector('.modal-work-title');
    const name = document.querySelector('.modal-name');
    const bio = document.querySelector('.modal-bio');
    endpoint.addEventListener('click', e => {
      const clickedInside = modal.contains(e.target);

      if (clickedInside) {
        return;
      }

      dataObj.shift();
      FetchCollectionEndpoint(entryId)
        .then(r => {
          dataObj.push(r[0]);
        })
        .then(() => {
          if (dataObj) {
            html.classList.add('modal-open');
            body.classList.add('modal-open');
            jobTitle.innerHTML = dataObj[0].job_title;
            name.innerHTML = `${`${dataObj[0].first_name} ${datafObj[0].last_name
              }`}`;
            bio.innerHTML = dataObj[0].short_bio;
            modalContainer.classList.add('modal-open');
          }
        })
        .catch(error => {
          if (error) {
            console.log('FETCH ERROR ', error);
          }
        });
    });
  });


  //* GDPR Cookie Banner Initialization
  //* Docs: https://www.osano.com/cookieconsent/documentation/javascript-api/
  window.cookieconsent.initialise({
    container: document.querySelector('footer'),
    theme: 'classic',
    content: {
      message: `We use technologies to personalize and enhance your experience on our site. Visit our <a href="https://www.breckinridge.com/privacy-notice/">Online Privacy Policy</a> and <a href="https://www.breckinridge.com/cookie-policy/">Cookie Policy</a> to learn more. By using our site, you agree to our use of these technologies.`,
      dismiss: `I understand`,
    },
    showLink: false,
    revokable: false,
    law: {
      regionalLaw: false,
    },
    cookie: {
      // This is the name of this cookie - you can ignore this
      name: 'cookieconsent_status',
      // This is the url path that the cookie 'name' belongs to. The cookie can only be read at this location
      path: '/',
      // This is the domain that the cookie 'name' belongs to. The cookie can only be read on this domain.
      //  - Guide to cookie domains - https://www.mxsasha.eu/blog/2014/03/04/definitive-guide-to-cookie-domains/
      domain: `${window.location.hostname}`,
      // The cookies expire date, specified in days (specify -1 for no expiry)
      expiryDays: 365,
      // If true the cookie will be created with the secure flag. Secure cookies will only be transmitted via HTTPS.
      secure: false,
    },
    // location: true,
  });

  //* Converts img src svgs to inline svgs
  const svgs = [...document.querySelectorAll('img.svg')];

  svgs.forEach(svg => {
    SvgConversion(svg);

    window.addEventListener('resize', e => {
      SvgConversion(svg);
    });
  });
});
